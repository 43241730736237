<template>
    <div>
        <!-- search bar -->
        <vs-row class="mt-3">
            <vs-col vs-xs="12" vs-sm="10" vs-lg="10" class="px-0 pr-2 mb-base">
                <vs-input
                    class="w-full"
                    placeholder="Type to Search"
                    v-model="filters.search"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="1" vs-lg="1" class="flex mb-base p-0">
                <vx-tooltip text="Refresh Data" color="primary" class="ml-4">
                    <vs-button color="primary" type="filled" class="w-full" @click="load_groups(filters)" :disabled="loading">
                        <font-awesome-icon :icon="redoIcon" />
                    </vs-button>
                </vx-tooltip>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="1" vs-lg="1" class="flex px-1">
                <vx-tooltip text="Add Tag" color="primary" class="ml-4">
                    <vs-button color="primary" type="filled" class="w-full" @click="edit_group" :disabled="submit_disabled">
                        <font-awesome-icon :icon="plusIcon" />
                    </vs-button>
                </vx-tooltip>
            </vs-col>
        </vs-row>

        <!-- table  -->
        <div class="vx-col w-full mb-base">
            <vx-card>
                <vs-table :data="groups" slot="no-body" :hoverFlat="true" stripe>
                    <vs-tr>
                        <vs-th>Name</vs-th>
                        <vs-th>Created On</vs-th>
                        <vs-th></vs-th>
                    </vs-tr>
                    <template>
                        <template v-for="(item, index) in groups">
                            <vs-tr :key="index">
                                <vs-td>
                                    {{ item.name }}
                                </vs-td>
                                <vs-td>
                                    <FormatText :text="item.created_on" format="datetime" />
                                </vs-td>
                                <vs-td class="text-right">
                                    <vs-button size="small" :disabled="loading" @click="edit_group(item)">Edit</vs-button>
                                    <vs-button size="small" :disabled="loading" color="danger" class="mx-2" @click="remove_group(item)">Delete</vs-button>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
            </vx-card>
        </div>

        <template v-if="!disable_pagination">
            <vs-row v-if="total_results" class="mt-3 text-center">
                <vs-pagination :total="total_pages" v-model="current_page" goto class="mx-auto"></vs-pagination>
            </vs-row>
        </template>

        <vs-popup :active.sync="popup.active" :title="`${popup.item.id ? 'Edit' : 'Add'} Tag`">
            <form @submit.prevent="submit">
                <vs-row>
                    <vs-col vs-xs="12" vs-md="12" vs-lg="12">
                        <vs-input
                            label="Name"
                            placeholder="e.g. Singapore"
                            v-model="popup.item.name"
                            class="w-full text-center"
                            :disabled="submit_disabled"
                            :danger="popup.api_errors.name"
                            :danger-text="popup.api_errors.name ? popup.api_errors.name.join(',') : null"
                        />
                    </vs-col>
                    <vs-col vs-xs="12" vs-md="12" vs-lg="12" class="mt-4 text-center">
                        <vs-button @click="submit()" :disabled="submit_disabled" size="small">Save</vs-button>
                    </vs-col>
                </vs-row>
            </form>
        </vs-popup>
    </div>
</template>

<script>
import { faPlus, faRedo } from '@fortawesome/free-solid-svg-icons'
import { Request } from '@/utils/requests'

export default {
    props: {
        embedded_mode: {
            required: false,
            default: false
        },
        embedded_filters: {
            required: false,
            default: () => {}
        },
        disable_pagination: {
            required: false,
            default: false
        },
        global_mode: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {

        let offset = parseInt(this.$route.query.offset || 0);
        let limit = parseInt(this.$route.query.limit || 20);

        let current_page = Math.ceil((offset + limit) / limit);

        return {
            groups: [],
            next_url: null,
            loading: false,
            total_results: null,
            current_page: current_page,

            filters: {
                search: this.$route.query.search || null,
                ordering: this.$route.query.ordering || [],
                offset: offset,
                limit: limit
            },

            popup: {
                active: false,
                item: {
                    name: null
                },
                api_errors: {}
            },

            submit_disabled: false
        }
    },
    computed:{
        total_pages() {
            if(this.total_results == 0 || this.filters.limit == 0)  return 1
            return Math.ceil(this.total_results/this.filters.limit)
        },
        redoIcon: () => faRedo,
        plusIcon: () => faPlus
    },
    mounted() {
        this.load_groups(this.filters)
    },
    watch: {
        filters: {
            deep: true,
            handler(newV) {
                if (this.current_page == null) return;

                if (this.timer) clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    this.$router.push({ name: this.$route.name, query: {...newV} });
                }, 800);
            }
        },
        "$route.query"(newV) {
            this.load_data(newV);
        },
        current_page(newV, oldV) {
            if (newV == oldV) return;
            this.filters.offset = (newV - 1) * this.filters.limit;
        }
    },
    methods: {
        edit_group(group) {
            if (!group)
                  this.popup.item = {name: null}
              else
                  this.popup.item = {...group}

              this.popup.api_errors = {}
              this.popup.active = true
        },
        remove_group(group) {
            this.$vs.dialog({
                type:'confirm',
                color: 'danger',
                title: `Are you Sure?`,
                text: 'Are you sure you want to remove Group "' + group.name + '"? This action is irreversible.',
                accept: () => this._remove_group(group)
            })
        },
        _remove_group(group){
            let url = this.$store.state.apiendpoints.entity_group
            url += group.id + '/'

            this.loading = true
            this.$vs.loading()

            Request(this, 'DELETE', url).then(
                (res) => {
                    this.loading = false
                    this.$vs.loading.close()
                    this.groups.splice(this.groups.findIndex(v => v.id == group.id), 1)
                    this.$vs.notify({
                        time: 2500,
                        title: 'Success',
                        text: "Group was deleted successfully.",
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'success'
                    })
                }, (err) => {
                    this.loading = false
                    this.$vs.loading.close()
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "An error occurred while processing your request. Please try again.",
                        iconPack: 'feather',
                        icon: 'icon-alert',
                        color: 'danger'
                    })
                }
            )
        },
        load_groups(filters) {
            this.loading = true;
            this.$vs.loading();

            let payload = filters
            if (!payload) payload = {};

            this.api_errors = {}

            Request(this, 'GET', this.$store.state.apiendpoints.entity_group_list, payload).then(
                (res) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    this.groups = res.results
                    this.total_results = res.count
                }, (err) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "Unknown error occured while loading data. Please try again.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
        submit() {
            let url = this.$store.state.apiendpoints.entity_group, request_method = 'POST'

            if(this.popup.item.id) {
                request_method = "PUT"
                url += this.popup.item.id + "/"
            }
            this.submit_disabled = true
            this.$vs.loading();

            this.api_errors = {}

            Request(this, request_method, url, null, this.popup.item).then(
                (res) => {
                    if(this.popup.item.id) {
                        this.groups[this.groups.findIndex(v => v.id == this.popup.item.id)] = res
                    } else {
                        this.groups.unshift(res)
                    }
                    this.popup.item.name = null
                    this.popup.item.id = null
                    this.popup.active = false

                    this.submit_disabled = false
                    this.$vs.loading.close()

                    this.$vs.notify({
                        time: 2500,
                        title: 'Saved',
                        text: "Group Saved Successfully.",
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'success'
                    })
                },
                (err) => {
                  this.submit_disabled = false
                    this.$vs.loading.close()
                    if(err.error) {
                        this.popup.api_errors = err.error
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Please fix the errors and try again",
                            iconPack: 'feather',
                            icon: 'icon-alert',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "An Error Occurred while processing your request. Please Try again",
                            iconPack: 'feather',
                            icon: 'icon-alert',
                            color: 'danger'
                        })
                    }
                }
            )
        },
    }
}
</script>
